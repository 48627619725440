import { ReactNode } from 'react'
import icodevLogo from 'assets/logos/d2dlogo.svg'
import AccountMenu from './menu'
import './style.scss'

type Props = {
  innerComponent?: ReactNode;
  hasAccess: boolean;
}

const defaultProps = {
  innerComponent: <div />,
}

export default function TopBar({ innerComponent, hasAccess }: Props) {
  return (
    <header>
      <div className="logos">
        <a className="flex-center" href="/">
          <img alt="icodev" src={icodevLogo} />
        </a>
      </div>
      {hasAccess && innerComponent}
      <AccountMenu />
    </header>
  )
}

TopBar.defaultProps = defaultProps
